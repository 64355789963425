import React, {useEffect, useCallback } from "react"
import { graphql } from 'gatsby';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useData } from "../utils/DataProvider"
import { revealText, coverReveal } from "../animations/Animate"
import Hero from "../components/Hero"
import SEO from "../components/Seo"
import LayoutInner from "../components/LayoutInner"
import ImageText from "../components/ImageText"

gsap.registerPlugin(ScrollTrigger);

const ConnectPage = ({data, location}) => {
  const [state, setState] = useData()
  const connectData = data.connectQuery;
  useEffect(() => {
    setState({ ...state, color: connectData.meta.color, page: 'connect' })
  }, [])

  useEffect(() => {
    connectAnimation();
  }, []);

  const connectAnimation = useCallback(() => {
    coverReveal();

    // DISABLED per client request
    // revealText('.jsContactTitle .ui-block__text', 5)
    // ScrollTrigger.batch('.jsContactEl', {
    //   onEnter: batch => gsap.from(batch, {
    //     y: 150,
    //     autoAlpha: 0,
    //     stagger: 0.3,
    //     duration: 1,
    //     ease: "power3.easeOut",
    //   })
    // });
  })

  return (
    <LayoutInner location={location}>
      <SEO title={connectData.meta.title} />
      <Hero data={connectData.heroQuery} />
      <ImageText data={connectData.sectionsQuery.section1} order={'image,content'} content={'connect'} />
    </LayoutInner>
  )
}

export default ConnectPage


export const query = graphql`
  query Connect {
    connectQuery: connectPageJson {
      meta {
        title
        shareImage
        keywords
        color
      }
      heroQuery: hero {
        title
        stripes
      }
      sectionsQuery: sections {
        section1 {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          contacts {
            company
            name
            position
            phone
            mobile
            email
            order
          }
        }
      }
    }
  }
`;

